import Resource from '@/api/resource';
import request from '@/utils/request';

class PostResource extends Resource {
  constructor() {
    super('admin/black-list');
  }

  destroy(wordID) {
    return request({
      url: '/' + this.uri + '/' + wordID,
      method: 'delete',
    });
  }

}

export { PostResource as default };
